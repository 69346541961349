import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { navigate } from "gatsby";
import { Button } from "gatsby-theme-material-ui";
import microRecImage from "../../../images/ecommerce/catalogue/1.MRC.gif";
import microRecImageHover from "../../../images/ecommerce/catalogue/microrec.png";
import microImage from "../../micro-to-4k/FIF.gif";
import microImageHover from "../../../images/ecommerce/microto4k/micro-to-4k.png";
import optiRecImage from "../../../images/ecommerce/catalogue/1.OPT.gif";
import optiRecImageHover from "../../../images/ecommerce/catalogue/OPT.png";
import quikRecImage from "../../../images/ecommerce/catalogue/Quikvue7.png";
import quikRecImageHover from "../../../images/ecommerce/catalogue/quikvue2.png";
import adapterImageHover from "../../../images/ecommerce/catalogue/ADT 3.png";
import adapterImage from "../../../images/ecommerce/img-adapter/1.ADZ.gif";
import beamsplitterImageHover from "../../../images/ecommerce/catalogue/BSZ 7.png";
import beamsplitterImage from "../../../images/ecommerce/img-beamsplitter/1. bs.gif";
import bluethoothImage from "../../../images/ecommerce/bluetooth/1 Button.png";
import takeanepImage from "../../../images/ecommerce/catalogue/1 TN.png";
import bluethoothImageHover from "../../../images/ecommerce/catalogue/2 Button.png";
import takeanepImageHover from "../../../images/ecommerce/catalogue/2 tn.png";
import Compatibility from "../compatibility/compatibility-catalogue";
import { Box } from "@mui/material";
import "../../../static/catalogue.css";

const ProductSectionMicroREC = ({
  optiRECStripe,
  microRECStripe,
  microStripe,
  quikvueStripe,
  bluetoothStripe,
  takeanepStripe,
  ...props
}) => {
  const [open, setOpen] = React.useState(false);
  const [tools, setTools] = React.useState([]);
  const [accessories, setAccessories] = React.useState([]);
  const [hoveredItem, setHoveredItem] = useState(null);

  useEffect(() => {
    setTools([microRECStripe, optiRECStripe, microStripe]);
    setAccessories([bluetoothStripe, takeanepStripe, quikvueStripe]);
  }, []);

  const handleClick = (product) => {
    handleBackDrop();
    setTimeout(() => {
      link2UpsellPage(product);
    }, 400);
  };

  const handleBackDrop = () => {
    setOpen(!open);
  };

  const link2UpsellPage = (product) => {
    navigate(`/store/${product}/`, {});
  };

  const getImageUrl = (product) => {
    const images = {
      microrec: microRecImage,
      optirec: optiRecImage,
      "micro-to-4k": microImage,
      quikvue: quikRecImage,
      beamsplitter: beamsplitterImage,
      adapter: adapterImage,
      "bluetooth-shutter": bluethoothImage,
      takeanep: takeanepImage,
    };

    return images[product];
  };
  const getImageUrlHover = (product) => {
    const images = {
      microrec: microRecImageHover,
      optirec: optiRecImageHover,
      "micro-to-4k": microImageHover,
      quikvue: quikRecImageHover,
      beamsplitter: beamsplitterImageHover,
      adapter: adapterImageHover,
      "bluetooth-shutter": bluethoothImageHover,
      takeanep: takeanepImageHover,
    };
    return images[product];
  };

  return (
    <section id="tools" style={{ marginTop: 20, marginBottom: 50 }}>
      <Container maxWidth={"lg"}>
        <>
          <Grid container style={{ marginBottom: "1em", alignItems: "center" }}>
            <Typography
              variant="h4"
              align={"left"}
              gutterBottom={true}
              sx={{ fontWeight: "bold", color: "#FFA100", marginRight: 2 }}
            >
              Digitization tools.
            </Typography>
            <Typography
              variant="h5"
              align={"left"}
              gutterBottom={true}
              sx={{ fontWeight: "bold", color: "#6e6e73" }}
            >
              High-Resolution Adapters and Optical Systems
            </Typography>
          </Grid>
          <Grid container justifyContent={"space-between"}>
            {tools.map((product, index) => [
              <Grid
                key={index} // Added a key prop here for uniqueness
                className="hover_over_product product_grid "
                onClick={() => handleClick(product.type)}
                container
                item
                xs={12}
                sm={5.5}
                md={3.5}
                style={{
                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                  margin: "10px",
                  borderRadius: "20px",
                }}
                onMouseOver={() => setHoveredItem(product.type)}
                onMouseOut={() => setHoveredItem(null)}
              >
                {product.displayName === "MicroREC" && (
                  <div className="best_seller">🔥 BEST SELLER</div>
                )}
                {product.displayName === "MicroTo4k" && (
                  <div className="best_seller">🚀 NEW</div>
                )}
                <Grid item xs={12} sx={{ justifyContent: "center" }}>
                  <Box
                    sx={{
                      position: "relative",
                      width: "100%",
                      height: "auto",
                      marginLeft: "auto",
                      marginRight: "auto",
                      borderTopLeftRadius: "20px",
                      borderTopRightRadius: "20px",
                      maxHeight: "250px",
                      minHeight: "250px",
                    }}
                    // className="product_box"
                    onMouseOver={() => setHoveredItem(product.type)}
                    onMouseOut={() => setHoveredItem(null)}
                  >
                    {product && product.type && (
                      <Box
                        component="img"
                        src={getImageUrl(product.type)}
                        alt={product.displayName}
                        // className="product-image"
                        sx={{
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          borderTopLeftRadius: "20px",
                          borderTopRightRadius: "20px",
                          transition: "opacity 0.3s ease",
                          opacity: hoveredItem === product.type ? 0 : 1,
                        }}
                      />
                    )}
                    {product && product.type && (
                      <Box
                        component="img"
                        src={getImageUrlHover(product.type)}
                        alt={product.displayName}
                        // className="product-image"
                        sx={{
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          borderTopLeftRadius: "20px",
                          borderTopRightRadius: "20px",

                          transition: "opacity 0.3s ease",
                          opacity: hoveredItem === product.type ? 1 : 0,
                        }}
                      />
                    )}
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    padding: "20px",
                  }}
                >
                  <Grid item>
                    <Typography
                      variant="h6"
                      gutterBottom={true}
                      className="product-title"
                    >
                      {product && product.displayName}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="body1"
                      paragraph={true}
                      gutterBottom={true}
                      sx={{
                        textAlign: "justify",
                        marginBottom: "2em",
                        fontSize: "17px",
                        color: "#6A6A6A",
                      }}
                    >
                      {product && product.displayDescription}
                    </Typography>{" "}
                    <hr style={{ marginBottom: "1em" }}></hr>
                  </Grid>
                  <Grid item sx={{ textAlign: "center" }}>
                    <Button
                      onClick={() => handleClick(product && product.type)}
                      color="primary"
                      variant="contained"
                    >
                      Learn More
                    </Button>
                  </Grid>
                </Grid>
              </Grid>,
            ])}
          </Grid>
        </>
        <>
          <Grid
            container
            style={{
              marginBottom: "1em",
              marginTop: "2em",
              alignItems: "center",
            }}
            id="attachments"
          >
            <Typography
              variant="h4"
              align={"left"}
              gutterBottom={true}
              sx={{ fontWeight: "bold", color: "#FFA100", marginRight: 2 }}
            >
              Attachments.
            </Typography>
            <Typography
              variant="h5"
              align={"left"}
              gutterBottom={true}
              sx={{ fontWeight: "bold", color: "#6e6e73" }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <div style={{ marginRight: "0.5em" }}>Compatibility kits. </div>
                <Compatibility />
              </div>
            </Typography>
          </Grid>
          <Grid container>
            <Grid
              className="hover_over_product"
              onClick={() => handleClick("beamsplitter")}
              container
              item
              xs={12}
              sm={5.5}
              md={3.5}
              style={{
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                margin: "10px",
                borderRadius: "20px",
              }}
              onMouseOver={() => setHoveredItem("beamsplitter")}
              onMouseOut={() => setHoveredItem(null)}
            >
              <Grid item sx={{ justifyContent: "center" }} xs={12}>
                <Box
                  sx={{
                    position: "relative",
                    width: "100%",
                    height: "auto",
                    marginLeft: "auto",
                    marginRight: "auto",
                    borderTopLeftRadius: "20px",
                    borderTopRightRadius: "20px",
                    maxHeight: "250px",
                    minHeight: "250px",
                  }}
                  onMouseOver={() => setHoveredItem("beamsplitter")}
                  onMouseOut={() => setHoveredItem(null)}
                >
                  <Box
                    component="img"
                    src={getImageUrl("beamsplitter")}
                    sx={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderTopLeftRadius: "20px",
                      borderTopRightRadius: "20px",
                      transition: "opacity 0.3s ease",
                      opacity: hoveredItem === "beamsplitter" ? 0 : 1,
                    }}
                  />
                  <Box
                    component="img"
                    src={getImageUrlHover("beamsplitter")}
                    sx={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderTopLeftRadius: "20px",
                      borderTopRightRadius: "20px",
                      transition: "opacity 0.3s ease",
                      opacity: hoveredItem === "beamsplitter" ? 1 : 0,
                    }}
                  />
                </Box>
              </Grid>
              <Grid style={{ padding: "20px" }} xs={12}>
                <Grid item>
                  <Typography
                    variant="h6"
                    gutterBottom={true}
                    sx={{ fontWeight: "bold", color: "#FFA100" }}
                  >
                    Beam Splitter
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="body1"
                    paragraph={true}
                    gutterBottom={true}
                    sx={{
                      textAlign: "justify",
                      fontSize: "17px",
                      color: "#6A6A6A",
                    }}
                  >
                    Essential for MicroREC Functionality
                  </Typography>{" "}
                  <hr style={{ marginBottom: "1em" }}></hr>
                </Grid>
                <Grid item sx={{ textAlign: "center" }}>
                  <Button
                    onClick={() => handleClick("beamsplitter")}
                    color="primary"
                    variant="contained"
                  >
                    Learn More
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              className="hover_over_product"
              item
              xs={12}
              sm={5.5}
              md={3.5}
              style={{
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                margin: "10px",
                borderRadius: "20px",
              }}
              onClick={() => handleClick("adapter")}
              onMouseOver={() => setHoveredItem("adapter")}
              onMouseOut={() => setHoveredItem(null)}
            >
              <Grid item sx={{ justifyContent: "center" }} xs={12}>
                <Box
                  sx={{
                    position: "relative",
                    width: "100%",
                    height: "auto",
                    marginLeft: "auto",
                    marginRight: "auto",
                    borderTopLeftRadius: "20px",
                    borderTopRightRadius: "20px",
                    maxHeight: "250px",
                    minHeight: "250px",
                  }}
                  onMouseOver={() => setHoveredItem("adapter")}
                  onMouseOut={() => setHoveredItem(null)}
                >
                  <Box
                    component="img"
                    src={getImageUrl("adapter")}
                    sx={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderTopLeftRadius: "20px",
                      borderTopRightRadius: "20px",
                      transition: "opacity 0.3s ease",
                      opacity: hoveredItem === "adapter" ? 0 : 1,
                    }}
                  />
                  <Box
                    component="img"
                    src={getImageUrlHover("adapter")}
                    sx={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderTopLeftRadius: "20px",
                      borderTopRightRadius: "20px",
                      transition: "opacity 0.3s ease",
                      opacity: hoveredItem === "adapter" ? 1 : 0,
                    }}
                  />
                </Box>
              </Grid>
              <Grid style={{ padding: "20px" }} xs={12}>
                <Grid item>
                  <Typography
                    variant="h6"
                    gutterBottom={true}
                    sx={{ fontWeight: "bold", color: "#FFA100" }}
                  >
                    Adapter
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="body1"
                    paragraph={true}
                    gutterBottom={true}
                    sx={{
                      textAlign: "justify",
                      fontSize: "17px",
                      color: "#6A6A6A",
                    }}
                  >
                    Custom Fit for Your Equipment
                  </Typography>
                  <hr style={{ marginBottom: "1em" }}></hr>
                </Grid>
                <Grid item sx={{ textAlign: "center" }}>
                  <Button
                    onClick={() => handleClick("adapter")}
                    color="primary"
                    variant="contained"
                  >
                    Learn More
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
        <>
          <Grid
            container
            style={{
              marginBottom: "1em",
              marginTop: "2em",
              alignItems: "center",
            }}
            id="accessories"
          >
            <Typography
              variant="h4"
              align={"left"}
              gutterBottom={true}
              sx={{ fontWeight: "bold", color: "#FFA100", marginRight: 2 }}
            >
              Accessories.
            </Typography>
            <Typography
              variant="h5"
              align={"left"}
              gutterBottom={true}
              sx={{ fontWeight: "bold", color: "#6e6e73" }}
            >
              Performance Boosters
            </Typography>
          </Grid>
          <Grid container>
            {accessories.map((product) => [
              <Grid
                container
                item
                xs={12}
                sm={5.5}
                md={3.5}
                className="hover_over_product"
                style={{
                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                  margin: "10px",
                  borderRadius: "20px",
                }}
                onClick={() => handleClick(product.type)}
                onMouseOver={() => setHoveredItem(product.type)}
                onMouseOut={() => setHoveredItem(null)}
              >
                <Grid item sx={{ justifyContent: "center" }} xs={12}>
                  <Box
                    sx={{
                      position: "relative",
                      width: "100%",
                      height: "auto",
                      marginLeft: "auto",
                      marginRight: "auto",
                      borderTopLeftRadius: "20px",
                      borderTopRightRadius: "20px",
                      maxHeight: "250px",
                      minHeight: "250px",
                    }}
                    onMouseOver={() => setHoveredItem(product.type)}
                    onMouseOut={() => setHoveredItem(null)}
                  >
                    <Box
                      component="img"
                      src={getImageUrl(product.type)}
                      alt={product.displayName}
                      sx={{
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderTopLeftRadius: "20px",
                        borderTopRightRadius: "20px",
                        transition: "opacity 0.3s ease",
                        opacity: hoveredItem === product.type ? 0 : 1,
                      }}
                    />
                    <Box
                      component="img"
                      src={getImageUrlHover(product.type)}
                      alt={product.displayName}
                      sx={{
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderTopLeftRadius: "20px",
                        borderTopRightRadius: "20px",
                        transition: "opacity 0.3s ease",
                        opacity: hoveredItem === product.type ? 1 : 0,
                      }}
                    />
                  </Box>
                </Grid>
                <Grid style={{ padding: "20px" }} xs={12}>
                  <Grid item>
                    <Typography
                      variant="h6"
                      gutterBottom={true}
                      sx={{ fontWeight: "bold", color: "#FFA100" }}
                    >
                      {product && product.displayName}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="body1"
                      paragraph={true}
                      gutterBottom={true}
                      sx={{
                        textAlign: "justify",
                        fontSize: "17px",
                        color: "#6A6A6A",
                      }}
                    >
                      {product && product.displayDescription}
                    </Typography>{" "}
                    <hr style={{ marginBottom: "1em" }}></hr>
                  </Grid>
                  <Grid item sx={{ textAlign: "center" }}>
                    <Button
                      onClick={() => handleClick(product.type)}
                      color="primary"
                      variant="contained"
                    >
                      Learn More
                    </Button>
                  </Grid>
                </Grid>
              </Grid>,
            ])}
          </Grid>
        </>
      </Container>
    </section>
  );
};

export default ProductSectionMicroREC;
